import React from 'react';

import RedBannerImage from '../../../assets/images/HJBannerRed.png';
import BlackBannerImage from '../../../assets/images/HJBannerBlack.png';

import Logo from '../../../assets/images/HJLogo.png';

const Banner = ({ mode = 'primary' }) => {
  return (
    <div className="flex flex-col items-center justify-center">
      <img
        alt="hungry-jacks-banner"
        src={mode === 'primary' ? BlackBannerImage : RedBannerImage}
        className="w-screen"
      />
      <img alt="hungry-jacks-logo" src={Logo} className="h-48 -mt-56" />
    </div>
  );
};

export { Banner };
