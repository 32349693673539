import React from 'react';

import {
  Footer,
  useTemplate,
  TimeoutModal,
  PrimaryButton,
  SecondaryButton,
  AccessibilityButton,
  AccessibilityWrapper,
} from '@fingermarkglobal/cringer.components';

import Standing from '@fingermarkglobal/cringer.components/src/assets/images/icons/accessibility/standing.svg';
import Wheelchair from '@fingermarkglobal/cringer.components/src/assets/images/icons/accessibility/wheelchair.svg';

import { Header } from '../../components/stateless/header';

const CustomDefaultTemplate = ({
  children,
  timeoutEnabled = true,
  footerEnabled = true,
  headerEnabled = true,
  templateOverride = false,
  isHeaderTitleEnabled = true,
  isHeaderTotalEnabled = false,
  isPreviousActionEnabled = true,
  showAccessibilityButton = true,
  enableCustomCancelOrder = false,
}) => {
  const {
    t,
    header,
    footer,
    isOnline,
    isCouponEnabled,
    isTimeoutEnabled,
    accessibilityEnabled,
    timeoutActionOverride,
    modalAccessibilityClass,
  } = useTemplate({
    timeoutEnabled,
    templateOverride,
  });

  const { homeMode = 'primary' } = header;

  const {
    spacer = null,
    showCouponButton = false,
    isPreviousActionEnabled: isPreviousActionEnabledFooter,
  } = footer;

  const enablePreviousActionOnFooter =
    isPreviousActionEnabledFooter !== undefined && isPreviousActionEnabledFooter !== null
      ? isPreviousActionEnabledFooter
      : isPreviousActionEnabled;

  const shouldShowCoupon = !spacer && isCouponEnabled && showCouponButton;
  const solidBackgroundColor = homeMode === 'primary' ? 'bg-black' : 'bg-home';

  return (
    <div className={`w-full bottom-0 absolute overflow-y-hidden h-full ${solidBackgroundColor}`}>
      <AccessibilityWrapper
        className="bg-default"
        isAccessibilityButtonEnabled={showAccessibilityButton}
      >
        <div className="flex flex-col w-screen h-full">
          {headerEnabled && (
            <Header
              {...header}
              t={t}
              mode={homeMode}
              isCouponEnabled={isCouponEnabled}
              isHeaderTitleEnabled={isHeaderTitleEnabled}
              isHeaderTotalEnabled={isHeaderTotalEnabled}
            />
          )}

          <div className="flex-grow overflow-auto">{children}</div>

          {footerEnabled && (
            <Footer
              {...footer}
              isMiniCartEnabled
              isAnimationTargetEnabled
              isFooterStyleVariationEnabled
              showBasketCount={false}
              isCouponEnabled={isCouponEnabled}
              CustomSecondaryButton={PrimaryButton}
              CustomNextActionButton={SecondaryButton}
              enableCustomCancelOrder={enableCustomCancelOrder}
              isPreviousActionEnabled={enablePreviousActionOnFooter}
            />
          )}

          {showAccessibilityButton && (
            <AccessibilityButton
              AccessibilityIcon={accessibilityEnabled ? Standing : Wheelchair}
              className={`z-10 absolute flex w-24 h-24 p-2 shadow-md left-8 ${
                spacer || shouldShowCoupon ? 'bottom-11' : 'bottom-16'
              }`}
            />
          )}

          {isTimeoutEnabled && isOnline && (
            <TimeoutModal
              onTimeout={timeoutActionOverride}
              modalAccessibilityClass={modalAccessibilityClass}
            />
          )}
        </div>
      </AccessibilityWrapper>
    </div>
  );
};

export { CustomDefaultTemplate };
