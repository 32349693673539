import React from 'react';
import { Banner } from '../banner';
import headerTitleIcon from '../../../assets/images/HJWhopperHeaderStripes.png';
import { CategoriesHeader, LoyaltyBanner } from '@fingermarkglobal/cringer.components';

const TitleIcon = () => <img alt="" src={headerTitleIcon} title="" className="w-24 h-24 " />;

const Header = ({
  mode = 'primary',
  title = null,
  className = '',
  isHeaderTitleEnabled = true,
  showCategoriesHeader = false,
  total = null,
  isHeaderTotalEnabled = false,
  formattedTaxes = null,
  t,
  showSubtotal = true,
  showLoyaltyBanner = false,
  computedAction = () => {},
} = {}) => {
  const justifyContent = isHeaderTotalEnabled ? 'justify-between' : 'justify-center';
  const shouldRenderTitleIcon = title && isHeaderTitleEnabled;

  return (
    <div data-test="header" className={`z-10 flex flex-col ${className}`}>
      {showCategoriesHeader && <CategoriesHeader isIconMinimal isItemRounded={false} />}
      {showLoyaltyBanner && <LoyaltyBanner computedAction={computedAction} />}
      {!showCategoriesHeader && <Banner mode={mode} />}

      {shouldRenderTitleIcon && (
        <div
          className={`flex text-6xl uppercase px-8 py-8 font-header items-center ${justifyContent}`}
        >
          <div className="flex items-center justify-center">
            <TitleIcon /> {title}
          </div>
          {isHeaderTotalEnabled && showSubtotal && (
            <div className="flex flex-col items-end">
              <div>{total}</div>
              {formattedTaxes && (
                <div className="mt-4 text-4xl font-primary">
                  {t('headerTotalTaxes')}: {formattedTaxes}
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export { Header };
