import React from 'react';

import {
  PrimaryButtonLarge,
  BannerWarning,
  HomeCustomisation,
} from '@fingermarkglobal/cringer.components';

import WashHandsImage from '../../../assets/images/wash-hands.png';
import DefaultSecondaryBackground from '../../../assets/images/HJSecondaryBG.png';

const SecondaryHome = ({
  t,
  healthMsg = true,
  primaryAction,
  tertiaryAction,
  secondaryAction,
  accessibilityEnabled,
  showTermsAndConditionsButton = false,
  setShowTermsAndConditionsModal = () => {},
}) => {
  return (
    <HomeCustomisation.Provider
      fallback={{
        background: {
          image: DefaultSecondaryBackground,
        },
      }}
    >
      <HomeCustomisation.Background />

      <div data-test="secondary-home" className="z-10 flex flex-col items-center w-full h-full">
        <div className="w-full space-y-20 text-center font-secondary">
          <p className="text-9xl">{t('homeTitle')}</p>

          {!accessibilityEnabled && <HomeCustomisation.HeroImage />}

          <div data-test="home-upper" className="flex flex-wrap items-stretch justify-around">
            {!!secondaryAction && (
              <PrimaryButtonLarge
                className="w-2/5 mx-4 mb-8"
                data-test="button-secondary"
                onClick={secondaryAction}
              >
                {t('homeSecondaryAction')}
              </PrimaryButtonLarge>
            )}

            <PrimaryButtonLarge
              className="w-2/5 mx-4 mb-8"
              data-test="button-primary"
              onClick={primaryAction}
            >
              {!!secondaryAction || !!tertiaryAction
                ? t('homePrimaryAction')
                : t('homePrimaryAlternativeAction')}
            </PrimaryButtonLarge>

            {!!tertiaryAction && (
              <PrimaryButtonLarge className="w-2/5 mx-4 mb-8" onClick={tertiaryAction}>
                {t('homeTertiaryAction')}
              </PrimaryButtonLarge>
            )}

            <div
              data-test="home-lower"
              className="flex flex-col-reverse items-center justify-start w-full h-full mt-12"
            >
              {showTermsAndConditionsButton && (
                <BannerWarning
                  t={t}
                  width="109"
                  className="w-4/5 mt-8"
                  data-test="banner-terms"
                  title={t('homeTermsAndConditionsBannerWarningTitle')}
                  description={t('homeTermsAndConditionsBannerWarningDescription')}
                  additionalDescriptionAction={() => setShowTermsAndConditionsModal(true)}
                  additionalDescription={t(
                    'homeTermsAndConditionsBannerWarningAdditionalDescription',
                  )}
                />
              )}

              {healthMsg && (
                <BannerWarning
                  t={t}
                  width="109"
                  className="w-4/5 mt-8"
                  data-test="banner-health"
                  image={WashHandsImage}
                />
              )}
            </div>
          </div>
        </div>

        <div className="flex-grow"></div>

        <p data-test="home-footer-title" className="mb-24 text-5xl text-white">
          {t('homeFooterTitle')}
        </p>
      </div>
    </HomeCustomisation.Provider>
  );
};

export { SecondaryHome };
