/* eslint-disable max-lines */
// Routes is for the applications workflow definitions.
import React from 'react';

import { RouteBuilder, base } from '@fingermarkglobal/router';

import { CustomHomeView } from './src/views/home';
import { CustomDefaultTemplate } from './src/templates/default';
import ConfirmationImageSource from './src/assets/images/HJConfirmation.png';

import mockSettings from './mock-settings.json';
import { version } from './package.json';

const ConfirmationImage = () => (
  <img alt="confirmation" className="h-1/3" src={ConfirmationImageSource} />
);

const config = {
  ...base,
  transactions: {
    ...base.transactions,
    config: {
      ...base.transactions.config,
      template: {
        ...base.transactions.config.template,
        showAccessibilityButton: false,
      },
      provider: {
        allowedLanguages: ['en'],
        showDotAfterInitials: true,
        showFullFirstName: true,
      },
    },
  },
  lock: {
    ...base.lock,
    config: {
      ...base.lock.config,
      template: {
        isLogoEnabled: true,
        footerEnabled: false,
        timeoutEnabled: false,
        showAccessibilityButton: false,
      },
    },
  },
  reset: {
    ...base.reset,
    config: {
      ...base.reset.config,
      template: {
        headerEnabled: false,
        footerEnabled: false,
        timeoutEnabled: false,
      },
      provider: {
        cancelOrder: true,
        useResetOrder: true,
      },
    },
  },
  home: {
    ...base.home,
    View: CustomHomeView,
    config: {
      ...base.home.config,
      template: {
        headerEnabled: true,
        footerEnabled: false,
        timeoutEnabled: false,
        isCustomBackgroundImageEnabled: false,
      },
    },
  },
  categories: {
    ...base.categories,
    config: {
      ...base.categories.config,
      template: {
        footerEnabled: true,
        templateOverride: true,
      },
      provider: {
        isCustomisationHeaderEnabled: true,
        isCategoryTotalizerEnabled: false,
        isAdditionalMessageEnabled: false,
        showQuantityControllerLabel: true,
        showAddonAvailability: true,
        hideMiniCartModal: true,
      },
    },
  },
  category: {
    ...base.category,
    config: {
      ...base.category.config,
      template: {
        footerEnabled: true,
        templateOverride: true,
      },
      provider: {
        displayDescription: false,
        showCategoriesHeader: true,
        isCustomisationHeaderEnabled: true,
        isCustomiseCategoryTitleEnabled: true,
        isCategoryTotalizerEnabled: false,
        isAdditionalMessageEnabled: false,
        showQuantityControllerLabel: true,
        showAddonAvailability: true,
        hideMiniCartModal: true,
      },
    },
  },
  beacon: {
    ...base.beacon,
    config: {
      ...base.beacon.config,
      provider: { primaryPath: '/categories', secondaryPath: '/reset' },
    },
  },
  coupon: {
    ...base.coupon,
    config: {
      ...base.coupon.config,
    },
  },
  validate: {
    ...base.validate,
    config: {
      ...base.validate.config,
      template: {
        headerEnabled: true,
        isHeaderTitleEnabled: false,
        footerEnabled: false,
        timeoutEnabled: false,
        templateOverride: true,
      },
    },
  },
  cart: {
    ...base.cart,
    config: {
      ...base.cart.config,
      provider: {
        upsellEnabled: true,
        showCategoriesHeader: true,
        showEnergyInformation: true,
        isCustomisationHeaderEnabled: true,
        isCategoryTotalizerEnabled: false,
        isAdditionalMessageEnabled: false,
        showQuantityControllerLabel: true,
        isConcatUpsellProductsEnabled: true,
        showAddonAvailability: true,
      },
      template: {
        isHeaderTotalEnabled: true,
      },
    },
  },
  payment: {
    ...base.payment,
    config: {
      ...base.payment.config,
      template: {
        headerEnabled: true,
        footerEnabled: true,
      },
    },
  },
  card: {
    ...base.card,
    config: {
      ...base.card.config,
      template: {
        headerEnabled: true,
        footerEnabled: false,
        timeoutEnabled: false,
        templateOverride: true,
      },
    },
  },
  refund: {
    ...base.refund,
    config: {
      ...base.refund.config,
      provider: { nextPath: '/reset' },
      template: {
        headerEnabled: true,
        footerEnabled: false,
        timeoutEnabled: false,
        templateOverride: true,
      },
    },
  },
  confirmation: {
    ...base.confirmation,
    config: {
      ...base.confirmation.config,
      provider: { nextPath: '/receipt' },
      props: {
        loadingImage: ConfirmationImage,
        isLogoEnabled: false,
      },
      template: {
        headerEnabled: true,
        footerEnabled: false,
        timeoutEnabled: false,
        templateOverride: true,
      },
    },
  },
  receipt: {
    ...base.receipt,
    config: {
      ...base.receipt.config,
      provider: {
        showEnergyInformation: true,
        useLargeButtons: false,
        showGSTExcludeMessage: true,
        showDotAfterInitials: true,
        showAddonAvailability: true,
        showFullFirstName: true,
        delayQRCodeRenderInMilliseconds: 5000,
      },
      template: {
        headerEnabled: true,
      },
    },
  },
  admin: {
    ...base.admin,
    config: {
      ...base.admin.config,
      provider: { version },
      template: {
        headerEnabled: false,
        footerEnabled: false,
        accessibilityEnabled: false,
        showAccessibilityButton: false,
      },
    },
  },
  notify: {
    ...base.notify,
    config: {
      ...base.notify.config,
      template: { headerEnabled: false, footerEnabled: false, timeoutEnabled: false },
    },
  },
  auth: {
    ...base.auth,
    config: {
      ...base.auth.config,
      template: {
        headerEnabled: false,
        footerEnabled: false,
        timeoutEnabled: true,
        accessibilityEnabled: false,
        showAccessibilityButton: false,
      },
    },
  },
  sms: {
    ...base.sms,
    config: {
      ...base.sms.config,
      provider: { showDotAfterInitials: true },
      template: { headerEnabled: false, footerEnabled: false },
    },
  },
  customer: {
    ...base.customer,
    config: {
      ...base.customer.config,
      template: {
        ...base.customer.config.template,
        footerEnabled: true,
      },
      provider: {
        isFirstNameRequired: true,
        isLastNameRequired: true,
        showCategoriesHeader: true,
        showEmail: false,
        firstNameMaxLength: 13,
      },
    },
  },
  combo: {
    ...base.combo,
    config: {
      ...base.combo.config,
      provider: {
        showComboInfo: true,
        showEnergyInformation: true,
        showSizeButton: true,
        showCategoriesHeader: true,
        isCustomisationHeaderEnabled: true,
        isCategoryTotalizerEnabled: false,
        isAdditionalMessageEnabled: false,
        showQuantityControllerLabel: true,
        showAddonAvailability: true,
        isCustomiseCategoryTitleEnabled: true,
      },
    },
  },
  composed: {
    ...base.composed,
    config: {
      ...base.composed.config,
      provider: {
        showComboInfo: true,
        showEnergyInformation: true,
        showSizeButton: true,
        showCategoriesHeader: true,
        isCustomisationHeaderEnabled: true,
        isCategoryTotalizerEnabled: false,
        isAdditionalMessageEnabled: false,
        showQuantityControllerLabel: true,
      },
    },
  },
  loyalty: {
    ...base.loyalty,
    config: {
      ...base.loyalty.config,
    },
  },
  offers: {
    ...base.offers,
    config: {
      ...base.offers.config,
    },
  },
};

const Routes = () => (
  <RouteBuilder
    Template={CustomDefaultTemplate}
    root={{
      overwriteSettings: mockSettings,
      resolverProps: {
        isRetryActive: true,
      },
    }}
    config={config}
  />
);

export { Routes };
